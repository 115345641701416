<template>
  <section>
    <template v-if="!loading && value">
      <UiTitle severity="h1" class="mb-1 !text-xl sm:!text-3xl">
        {{ value.title }}
      </UiTitle>
      <UiButton
        v-if="value.address"
        class="mt-1 flex rounded-lg !bg-secondary !p-2 text-left text-xs text-text-main sm:!p-3 sm:text-sm sm:text-text-secondary"
        @click="copyAddress"
      >
        {{ value.address.normalized }}
        <UiIcon name="square-2-stack" class="ml-3 size-5 min-w-5 text-icon-quaternary" />
      </UiButton>
      <p v-if="value && !value.repertoire.length" class="pl-3 font-semibold">
        похоже, здесь мероприятий пока не запланировано
      </p>
    </template>
    <template v-else>
      <VenueBaseInfoSkeleton />
    </template>
  </section>
</template>

<script setup lang="ts">
import copyToClipboard from 'copy-to-clipboard'
import { toast } from 'vue3-toastify'
import type { Venue } from '@/5_entities/Venue'
import { UiButton, UiIcon, UiTitle } from '@/6_shared/ui'
import VenueBaseInfoSkeleton from './VenueBaseInfoSkeleton.vue'

type PropType = {
  value?: Venue
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const copyAddress = () => {
  if (!props.value?.address) return

  copyToClipboard(props.value.address.normalized)
  toast.success('Адрес скопирован')
}
</script>
