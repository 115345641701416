<template>
  <main class="pb-10 pt-16 md:pt-18">
    <UiContainer class="mb-9 flex items-center gap-2 md:mb-18 lg:gap-3">
      <VenuePoster :value="venue" :loading="!initialized" />
      <VenueBaseInfo :value="venue" :loading="!initialized" class="relative" />
    </UiContainer>
    <VenueRepertoire
      :id="GlobalIds['venueRepertoire']"
      class="md:mb-8"
      :class="{ '!-mb-18': venue && !venue?.repertoire.length && !venue?.availableDates.length }"
      :value="venue"
      :loading="!initialized"
      :loading-repertoire="!repertoireLoaded"
      :active-date="activeDate"
      :all-repertoire-loaded="allRepertoireLoaded"
      :repertoire-page-loaded="repertoireNextPageLoaded"
      :repertoire-load-error="repertoireLoadError"
      :repertoire-load-page-error="repertoireLoadPageError"
      @show-timetable="openTimetable"
      @update:active-date="changeActiveDate"
      @load-more="loadRepertoireNextPage(filters)"
      @reload-date="getRepertoire(filters)"
    />
    <VenueAddressInfo :value="venue" :loading="!initialized" class="-mt-3.5 mb-4.5 md:mt-0" />
    <VenueDescription :value="venue" :loading="!initialized" />
    <UiContainer v-if="venue?.repertoire?.length" class="!fixed bottom-4 z-30 w-full lg:hidden">
      <UiObserverHelperButton
        v-model:visible="isHelperButtonVisible"
        :title="venue.title"
        :image="venue.image"
        :observable-element-id="GlobalIds['venueRepertoire']"
        class="w-full"
        @click="goToRepertoire"
      >
        <template #title> выбрать мероприятие </template>
      </UiObserverHelperButton>
    </UiContainer>
    <UiDialog
      :model-value="showEventTimetable"
      position="bottom"
      :fit="isLgAndUp"
      :swipe-close="isLgAndDown"
      @update:model-value="closeTimetable"
    >
      <div
        class="relative h-[calc(100dvh_-_16px)] w-full rounded-t-3xl border border-card-primary bg-primary lg:h-[calc(100dvh_-_64px)] lg:w-[58vw] lg:min-w-[739px] lg:!max-w-[58vw]"
      >
        <UiButton
          icon
          class="!absolute !top-0.5 right-4 z-30 size-10 bg-primary lg:-top-7 lg:right-5 lg:size-7 lg:!bg-transparent"
          @click="closeTimetable"
        >
          <UiIcon name="x-mark" class="size-7 text-icon-tertiary hover:text-icon-secondary" />
        </UiButton>
        <EventTimetable
          :value="eventForTimetable"
          :loading="!eventInitialized"
          :active-date="timetableEventDate"
          show-title
          class="scrollbar-hidden h-full overflow-y-scroll !rounded-t-3xl"
        />
      </div>
    </UiDialog>
  </main>
</template>

<script setup lang="ts">
import { isSameDay } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep.js'
import { storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, defineAsyncComponent, onBeforeMount, ref, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import {
  VenueAddressInfo,
  VenueBaseInfo,
  VenueDescription,
  VenuePoster,
  VenueRepertoire
} from '@/3_widgets/Venue'
import { type Event, useEvent } from '@/5_entities/Event'
import { useVenue, type VenueFilters } from '@/5_entities/Venue'
import { usePageFilters, useQueryParamsOpenState } from '@/6_shared/composables'
import { getElementByGlobalId, GlobalIds } from '@/6_shared/config'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiContainer, UiDialog, UiIcon, UiObserverHelperButton } from '@/6_shared/ui'

const EventTimetable = defineAsyncComponent({
  loader: async () => {
    const { EventTimetable } = await import('@/3_widgets/Event')

    return EventTimetable
  }
})

const route = useRoute()
const { getVenue, getRepertoire, loadRepertoireNextPage } = useVenue()
const {
  venue,
  initialized,
  allRepertoireLoaded,
  repertoireLoaded,
  repertoireNextPageLoaded,
  repertoireLoadError,
  repertoireLoadPageError
} = storeToRefs(useVenue())
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
const initPage = (id: number) => {
  getVenue(Number(id))
  openTimeTableFromQuery()
}
onBeforeMount(() => {
  initPage(Number(route.params.id as string))
})

const showEventTimetable = ref(false)
const { getEvent } = useEvent()
const { event, eventInitialized } = storeToRefs(useEvent())
const timetableEventDate = ref<Undefinable<string>>(undefined)
const eventForTimetable = computed(() => {
  if (!event.value || !venue.value) return

  const newEvent = cloneDeep(event.value)
  newEvent.seances = newEvent.seances.filter((item) => item?.venue?.id === venue.value?.id)

  return newEvent
})
const { setParamInRouteQuery: changeEventTimetableQuery } = useQueryParamsOpenState(
  'timetable',
  () => {
    // внутри открываются другие модалки.
    // так что нужно проверять, что закрылись именно они, а не текущая
    if (showEventTimetable.value && !route.query?.timetable) {
      showEventTimetable.value = false
    }

    if (!showEventTimetable.value && route.query?.timetable) openTimeTableFromQuery()
  }
)
const openTimetable = (eventId: Event['id'], date: string) => {
  timetableEventDate.value = date
  getEvent(eventId)
  showEventTimetable.value = true

  changeEventTimetableQuery(`${eventId}:${timetableEventDate.value}`)
}
const openTimeTableFromQuery = () => {
  if (route.query.timetable) {
    const [eventId, activeDate] = (route.query.timetable as string).split(':')
    openTimetable(Number(eventId), activeDate)
  }
}
const closeTimetable = () => {
  showEventTimetable.value = false
  changeEventTimetableQuery(undefined)
}
const isHelperButtonVisible = ref(false)
const goToRepertoire = () => {
  getElementByGlobalId(GlobalIds['venueRepertoire'])?.scrollIntoView({
    behavior: 'smooth'
  })
}

// active date
const { filters, updateDateFilters } = usePageFilters<VenueFilters>(10)
const activeDate = shallowRef<Date | Date[] | undefined>(undefined)
watch(
  () => venue.value,
  (val) => val?.availableDates?.length && changeActiveDate(val.availableDates[0])
)

const changeActiveDate = (date: Date | Date[]) => {
  if (
    activeDate.value &&
    !Array.isArray(date) &&
    !Array.isArray(activeDate.value) &&
    isSameDay(date, activeDate.value)
  )
    return
  activeDate.value = date
  updateDateFilters(date)
  getRepertoire(filters.value)
}
</script>
